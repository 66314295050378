import 'core-js/features/promise';  
import 'core-js/features/string';


const scrollToPostion = function (name: string) {
  document
    .getElementById(name)
    ?.scrollIntoView({ behavior: "smooth", block: "start" });
};


var sideLanguage:string = "";

const changeLanguage = function()
{
 if (sideLanguage=="") 
 {
   sideLanguage = navigator.language    
 }
 if (sideLanguage.startsWith("de")) 
 {
   setText("en");
   sideLanguage = "en;"
 } else 
 {
  setText("de");
  sideLanguage = "de"
 }
}

const setText= function(userLang:string) 
{
  const german:boolean= ((userLang as string).startsWith("de"))
  if (german) {
    assignText("kleinod","Kleinod ist ein altes deutsches Wort für ein Schmuckstück.<br>Heute wird es überwiegend im übertragenen Sinne für eine Kostbarkeit benutzt.");
    assignText("subKleinod","Wir erschaffen digitale Kostbarkeiten für das 21. Jahrhundert.");
    assignText("why","Wir glauben das kleine hochqualifizierte, begeisterte Teams die beste Organisationsform sind, um überragende digitale Produkte zu bauen, die Menschen begeistern und die Welt verändern. Wir lieben das Gefühl ein Werk zu schaffen, das die eigene Handschrift trägt und von vielen Menschen genutzt wird.");
    assignText("how","Wir wollen Teil der Erfolgsgeschichte unserer Kunden sein und nicht nur eine Auftragsarbeit abliefern. Wir wollen zusammen mit unseren Kunden Produkte entwickeln, mit denen wir uns identifizieren und die wir technologisch und inhaltlich zusammen mit unseren Kunden gestalten.");    
    assignText("what","Wir entwickeln Cloud-native Software auf Basis eines modernen Technologie-Stacks für die Gesundheits- und Finanzindustrie.");    
    assignText("mHome","Home");    
    assignText("msHome","Home");    
    assignText("mAbout","Über uns");    
    assignText("msAbout","Über uns");    
    assignText("mProducts","Produkte");    
    assignText("msProducts","Produkte");    
    assignText("mContact","Kontakt");    
    assignText("msContact","Kontakt");    
    assignText("msClose","Schließen");    
    assignText("imprint","Impressum");   
    assignText("HowCaption","Wie") ;
    assignText("WhyCaption","Warum") ;
    assignText("WhatCaption","Was") ;
  } else 
  {
    assignText("kleinod","Kleinod is an old German word for gem.<br> Today it is mostly used figuratively for something precious.");
    assignText("subKleinod","We create digital products for the 21<sup>st</sup> century");
    assignText("why","We believe that small but highly skilled and enthusiastic teams are the best form of a business/organisation to build superior digital products; products that are robust, efficient and ultimately enhance a business/organisation productivity and bottom line. We relish the challenge of creating bespoke products that bears our own signature and are adopted by multiple businesses/organisations.");
    assignText("how","We want to be part of our customers' success story. We want to work hand in hand with our customers to understand their business/organisation needs in terms of technology and content. Together we will develop, design and deliver the bespoke solution.");    
    assignText("what","We develop cloud-native software based on a modern technology stack for the healthcare and financial industries.");    
    assignText("mHome","Home");    
    assignText("msHome","Home");    
    assignText("mAbout","About");    
    assignText("msAbout","About");    
    assignText("mProducts","Products");    
    assignText("msProducts","Products");    
    assignText("mContact","Contact");    
    assignText("msContact","Contact");    
    assignText("msClose","Close");   
    assignText("imprint","Imprint");    
    assignText("HowCaption","How we do") ;
    assignText("WhyCaption","Why we do") ;
    assignText("WhatCaption","What we do") ;
  }
}

function assignText(element:string,text:string)  
{
  const node = document.getElementById(element);
  if (node!=null) 
  {
    node.innerHTML = text;
  }
}

const animateHome = function() 
{
  animateCSS(".homeText","fadeInRight");  
}

function hideElement(element:string) 
{
  const node = document.querySelector(element);
  node?.classList.add("hide");
}

const animateAbout = function() 
{
  hideElement(".why");
  hideElement(".how");
  hideElement(".what");
  drawGoldenCircle()
  setTimeout(() => {
    animateCSS(".why","zoomIn");
  }, 0);  
  setTimeout(() => {
    animateCSS(".how","zoomIn");
  }, 1000);  
  setTimeout(() => {
    animateCSS(".what","zoomIn");
  }, 2000);    
  
}



const animateCSS = (element: any, animation: any, prefix = "animate__") =>
  // We create a Promise and return it
  new Promise((resolve, reject) => {
    const animationName = `${prefix}${animation}`;
    const node = document.querySelector(element);

    node.classList.remove(`hide`);
    node.classList.add(`${prefix}animated`, animationName);

    // When the animation ends, we clean the classes and resolve the Promise
    function handleAnimationEnd(event: { stopPropagation: () => void }) {
      event.stopPropagation();
      node.classList.remove(`${prefix}animated`, animationName);
      resolve("Animation ended");
    }

    node.addEventListener("animationend", handleAnimationEnd, { once: true });
  });

const drawGoldenCircle = function () {
  let start: number = 0;
  let step = 1;
  const animationTime = 1000;
  const animate = function () {
    if (start === 0) start = Date.now();
    const elapsed = Date.now() - start;
    var c = document.getElementById("myCanvas") as HTMLCanvasElement;
    var ctx = c.getContext("2d") as CanvasRenderingContext2D;
    // ctx.globalAlpha = elapsed/animationTime;
    const centerTop = 200;
    const centerLeft = 200;
    ctx.clearRect(0, 0, 400, 400); // clear canvas
    ctx.lineWidth = 2;
    ctx.strokeStyle = "white";
    if (step == 1) {
      ctx.beginPath();
      ctx.arc(
        centerLeft,
        centerTop,
        50,
        0,
        (2 * Math.PI * elapsed) / animationTime
      );
      ctx.stroke();
      ctx.fillStyle =`rgba(255, 255, 255, ${elapsed/animationTime})`
      ctx.fillText("Why", centerLeft, centerTop);
    } else if (step >1) {
      ctx.beginPath();
      ctx.arc(centerLeft, centerTop, 50, 0, 2 * Math.PI);
      ctx.stroke();      
      ctx.fillText("Why", centerLeft, centerTop);
    }
    if (step == 2) {
      ctx.beginPath();
      ctx.arc(
        centerLeft,
        centerTop,
        100,
        0,
        (2 * Math.PI * elapsed) / animationTime
      );
      ctx.stroke();
      ctx.fillStyle =`rgba(255, 255, 255, ${elapsed/animationTime})`
      ctx.fillText("How", centerLeft, centerTop + 75);
    } else if (step >2) {
      ctx.beginPath();
      ctx.arc(centerLeft, centerTop, 100, 0, 2 * Math.PI);
      ctx.stroke();
      ctx.fillText("How", centerLeft, centerTop + 75);
    }
    if (step == 3) {
      ctx.beginPath();
      ctx.arc(
        centerLeft,
        centerTop,
        150,
        0,
        (2 * Math.PI * elapsed) / animationTime
      );
      ctx.stroke();
      ctx.fillStyle =`rgba(255, 255, 255, ${elapsed/animationTime})`
      ctx.fillText("What", centerLeft, centerTop + 125);
    } else if (step >3) {
      ctx.beginPath();
      ctx.arc(
        centerLeft,
        centerTop,
        150,
        0,
        (2 * Math.PI * elapsed) / animationTime
      );
      ctx.stroke();
      ctx.fillText("What", centerLeft, centerTop + 125);
    }
    ctx.font = "15px calibri";
    ctx.fillStyle = "white";
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    if (elapsed < animationTime) {
      // Stop the animation after 2 seconds
      window.requestAnimationFrame(animate);
    } else {
      if (step < 3) {
        step++;
        start = 0;
        window.requestAnimationFrame(animate);
      }
    }
  };
  window.requestAnimationFrame(animate);
};

module.exports = {
  changeLanguage,
  animateHome,
  animateAbout,
  setText,
  drawGoldenCircle,
  scrollToPostion,
  animateCSS,
};

